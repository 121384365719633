
export default {
  data() {
    return {

      form : {
        id : '',
        nama_pegawai : '',
        nik_pegawai : '',
        jabatan : '',
        tanggal_lahir_pegawai: '',
        tempat_lahir_pegawai: '',
        jenis_kelamin_pegawai: '',
        status_keluarga_pegawai: '',
        agama_pegawai: '',

        no_npwp_pegawai: '',
        alamat_pegawai: '',
        kota_pegawai: '',
        kode_pos_pegawai: '',
        no_telpon_pegawai: '',
        hari_kerja_pegawai: '',


        file : null,
        file_old : null,
      },

      
      list_data : [],

      jk: [
          'Laki-laki','Perempuan'
      ],

      agama: [
          'Islam','Kahtolik', 'Protestan', 'Hindu', 'Buddha', 'Kong Hu Cu'
      ],

      status: [
          'Menikah','Belum Menikah', 'Duda', 'Janda'
      ],

      hk: [
          'Senin - Jumat','Senin - Sabtu', 'Setiap Hari', 'Hari Tertentu'
      ],


      page_first: 1,
      page_last: 0,
      cari_value: "",
      cek_load_data : true,
      file_path: this.$store.state.url.URL_APP + "uploads/",
  

      mdl_add: false,
      mdl_edit: false,
      mdl_hapus : false,
      mdl_lihat: false
    }
  },
  methods: {


    getView : function(){
      this.$store.commit("shoWLoading");
      fetch(this.$store.state.url.URL_Pegawai + "view", {
          method: "POST",
          headers: {
          "content-type": "application/json",
          authorization: "kikensbatara " + localStorage.token
          },
          body: JSON.stringify({
              data_ke: this.page_first,
              cari_value: this.cari_value
          })
      })
          .then(res => res.json())
          .then(res_data => {
              this.list_data = res_data.data;
              this.page_last = res_data.jml_data;
              this.$store.commit("hideLoading");
      });
    },


    addData : function() {
        var formData = new FormData();
        formData.append('data', JSON.stringify(this.form))
        formData.append('file', this.form.file)
  
  
        fetch(this.$store.state.url.URL_Pegawai + "addData", {
            method: "POST",
            headers: {
              // "content-type": "application/json",
              authorization: "kikensbatara " + localStorage.token
            },
            body: formData
        }).then(res_data => {
            this.Notify('Sukses Menambah Data', 'primary', 'check_circle_outline');
            this.getView();
        });
      },


      editData : function(){
        var formData = new FormData();
        formData.append('data', JSON.stringify(this.form))
        formData.append('file', this.form.file)
        fetch(this.$store.state.url.URL_Pegawai + "editData", {
            method: "POST",
            headers: {
              // "content-type": "application/json",
              authorization: "kikensbatara " + localStorage.token
            },
            body: formData
        }).then(res_data => {
            this.Notify('Sukses Merubah Data', 'warning', 'check_circle_outline');
            this.getView();
        });
      },
  
      removeData : function(a, b){
        fetch(this.$store.state.url.URL_Pegawai + "removeData", {
            method: "POST",
            headers: {
              "content-type": "application/json",
              authorization: "kikensbatara " + localStorage.token
            },
            body: JSON.stringify({id : a, file : b})
        }).then(res_data => {
            this.Notify('Sukses Menghapus Data', 'negative', 'check_circle_outline');
            this.getView();
        });
  
      },


    selectData : function(data){
        this.form.id = data.id;
        this.form.nama_pegawai = data.nama_pegawai;
        this.form.nik_pegawai = data.nik_pegawai;
        this.form.jabatan = data.jabatan;
        this.form.tanggal_lahir_pegawai = data.tanggal_lahir_pegawai.substring(0,10)
        this.form.tempat_lahir_pegawai = data.tempat_lahir_pegawai;
        this.form.jenis_kelamin_pegawai = data.jenis_kelamin_pegawai;
        this.form.status_keluarga_pegawai = data.status_keluarga_pegawai;
        this.form.agama_pegawai = data.agama_pegawai;


        this.form.no_npwp_pegawai = data.no_npwp_pegawai;
        this.form.alamat_pegawai = data.alamat_pegawai;
        this.form.kota_pegawai = data.kota_pegawai;
        this.form.kode_pos_pegawai = data.kode_pos_pegawai;
        this.form.no_telpon_pegawai = data.no_telpon_pegawai;
        this.form.hari_kerja_pegawai = data.hari_kerja_pegawai;



        this.form.file = data.foto_pegawai;
        this.form.createAt = data.createAt;
        this.form.file_old =  data.foto_pegawai;
    },

    // ====================================== PAGINATE ====================================
        Notify : function(message, positive, icon){
          this.$q.notify({
            message: message,
            color: positive,
            icon: icon,
            position : 'top',
            timeout: 500,
          })
        },
        btn_prev : function(){
            this.cek_load_data = true;
            if(this.page_first>1){
                this.page_first--
            }else{
                this.page_first = 1;
            }
            this.getView();
        },

        btn_next : function(){
            if(this.page_first >= this.page_last){
                this.page_first == this.page_last
            }else{
                this.page_first++;
            }
            this.getView();
        },

        cari_data : function(){
            this.page_first = 1;
            this.getView();
        },

        


    // ====================================== PAGINATE ====================================

        onFileSelected: function(event) {
          this.form.file = event.target.files[0];
        },

        konversiFormatTanggal(tanggal) {
          // Split tanggal berdasarkan tanda "-"
          var tanggalArray = tanggal.split('-');

          // Urutan: tahun, bulan, tanggal
          var tahun = tanggalArray[0];
          var bulan = tanggalArray[1];
          var tanggalBaru = tanggalArray[2];

          // Gabungkan kembali dalam format yang diinginkan dengan menambahkan "/"
          var tanggalHasil = tahun + '/' + bulan + '/' + tanggalBaru;
          // const tanggalHasil = new Date(parseInt(tahun), parseInt(bulan) - 1, parseInt(tanggalBaru));


          return tanggalHasil;
      },



  },

  mounted () {
    var get_profile = JSON.parse(localStorage.profile);
      
      this.status_user = get_profile.profile.status;
    this.getView();
  },
}
